<template>
  <div class="wrapper">
    <menu-ver type="superuser" name="Superuser" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>

      <table class="table" v-if="superusers && superusers.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.superusers.id") }}</th>
            <th scope="col">{{ $t("maintenance.superusers.name") }}</th>
            <th scope="col">{{ $t("maintenance.superusers.state") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in superusers" :key="item.usu_id">
            <td>{{ item.usu_id }}</td>
            <td>{{ item.usu_nombre }}</td>
            <td>
              <i
                style="margin-left: 18px"
                v-if="item.usu_bloqueado === 1"
                class="fas fa-lock"
              ></i>
              <i v-else style="margin-left: 18px" class="fas fa-lock-open"></i>
            </td>
            <td>
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.superusers.addTitle", { name: `${superuserId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="name"
                v-model.trim="usu_nombre"
                :placeholder="$t(`maintenance.superusers.name`) + '*'"
                maxlength="20"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="email"
                v-model.trim="usu_email"
                :placeholder="$t(`maintenance.superusers.email`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                id="password"
                v-model.trim="usu_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                id="password"
                v-model.trim="usu_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addSuperuser"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.superusers.deleteTitle", {
                name: `${superuserId}`,
              })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("maintenance.superusers.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteSuperuser"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.superusers.editTitle", { name: `${superuserId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="ename"
                v-model.trim="usu_nombre"
                :placeholder="$t(`maintenance.superusers.name`) + '*'"
                maxlength="20"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="eemail"
                v-model.trim="usu_email"
                :placeholder="$t(`maintenance.superusers.email`) + '*'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                id="epassword"
                v-model.trim="usu_clave"
                :placeholder="$t(`maintenance.superusers.password`) + ' *'"
                maxlength="20"
              />
              <input
                v-else
                type="text"
                class="form-control"
                id="epassword"
                v-model.trim="usu_clave"
                :placeholder="$t(`maintenance.superusers.password`) + ' *'"
                maxlength="20"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label" style="display: block">{{
                $t(`maintenance.superusers.state`) + " *"
              }}</label>
              <input
                v-if="superuser.usu_bloqueado !== 0"
                style="text-transform: uppercase"
                type="text"
                class="form-control input-state"
                id="state"
                value="Bloqueado"
                disabled
              />
              <input
                v-else
                style="text-transform: uppercase; width: 100%"
                type="text"
                class="form-control input-state"
                id="state"
                value="No Bloqueado"
                disabled
              />
              <button
                v-if="superuser.usu_bloqueado !== 0"
                @click="unlockUser"
                class="btn btn-outline-secondary btn-state"
                type="button"
              >
                {{ $t("maintenance.superusers.lock") }}
              </button>
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editSuperuser"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 3">
                {{ $t("errors.4") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 3"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      superusers: [],
      superuser: {},
      usu_nombre: "",
      usu_clave: "",
      usu_email: "",
      superuserId: "",
      result: "",
      action: 0,
      id: "",
      formError: false,
      loading: false,
      visPas: true,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      messageError: ''
    };
  },
  created() {
    this.getSuperusers();
    this.changeBreadCrumb(4);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor"])
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getSuperusers() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=superusu&ac=listado`
        );
        if (res.data) {
          this.superusers = res.data.data;
        }
        // console.log(this.superusers);
      } catch (error) {
        console.error(error);
      }
    },
    async addSuperuser() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const superuser = {
            usu_nombre: this.usu_nombre.toUpperCase(),
            usu_clave: this.usu_clave,
            usu_email: this.usu_email.toUpperCase(),
          };
          // console.log(superuser);
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=superusu&ac=grabar`,
            superuser
          );
          this.result = res.data.RESULT;
        } catch (error) {
          console.error(error);
          this.result = "1";
        }
        $("#addModal").modal("hide");
        $("#responseModal").modal("show");
        this.loading = false;
      }
    },
    async editSuperuser() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const editSU = {
            usu_id: this.superuser.usu_id,
            usu_nombre: this.usu_nombre.toUpperCase(),
            usu_clave: this.usu_clave,
            usu_email: this.usu_email,
          };
          // console.log(editSU);
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=superusu&ac=actualizar`,
            editSU
          );
          // console.log(res);
          this.result = res.data.RESULT;
        } catch (error) {
          console.error(error);
          this.result = "1";
        }
        $("#editModal").modal("hide");
        $("#responseModal").modal("show");
        this.loading = false;
      }
    },
    async deleteSuperuser() {
      this.result = "";
      try {
        const borrado = {
          usu_id: this.id,
        };
        // console.log(borrado);
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=superusu&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.superuserId = event[0];
    },
    validateForm() {
      if (this.usu_nombre === "") {
        this.formError = true;
        this.messageError = this.$t("errors.fieldOb");
        return false;
      }
      if (!this.isEmailValid()) {
        this.formError = true;
        this.messageError = this.$t("errors.fieldOb");
        return false;
      }
      if (!this.validatePassword()) {
        this.formError = true;
        this.messageError = this.$t("configuration.messageError2");
        return false;
      }
      return true;
    },
    cleanForms() {
      this.superuser = {
        usu_id: "",
        usu_nombre: "",
      };
      this.usu_nombre = "";
      this.usu_clave = "";
      this.usu_email = "";
      this.formError = false;
      this.getSuperusers();
      this.loading = false;
      this.visPas = true;
      this.messageError = '';
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.superuser = item;
        this.usu_nombre = item.usu_nombre;
        this.usu_clave = item.usu_clave;
        this.usu_email = item.usu_email;
        this.id = item.usu_id;
        return;
      }
      if (item.usu_id) {
        this.id = item.usu_id;
      }
    },
    async unlockUser() {
      try {
        const unlock = {
          usu_id: this.id,
        };
        // console.log(unlock);
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=superusu&ac=desbloquear`,
          unlock
        );
        // console.log(res);
        if (res.data.RESULT === "0") {
          this.superuser.usu_bloqueado = 0;
          this.action = 3;
          this.cleanForms();
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } else {
          this.result = res.data.RESULT;
        }
        this.getSuperusers();
      } catch (error) {
        console.error(error);
      }
    },
    isEmailValid() {
      return this.usu_email == ""
        ? ""
        : this.reg.test(this.usu_email)
        ? true
        : false;
    },
    validatePassword() {
      if (this.usu_clave.length >= 8) {
        let mayuscula = false;
        let minuscula = false;
        let numero = false;
        let caracter_raro = false;

        for (var i = 0; i < this.usu_clave.length; i++) {
          if (
            this.usu_clave.charCodeAt(i) >= 65 &&
            this.usu_clave.charCodeAt(i) <= 90
          ) {
            mayuscula = true;
          } else if (
            this.usu_clave.charCodeAt(i) >= 97 &&
            this.usu_clave.charCodeAt(i) <= 122
          ) {
            minuscula = true;
          } else if (
            this.usu_clave.charCodeAt(i) >= 48 &&
            this.usu_clave.charCodeAt(i) <= 57
          ) {
            numero = true;
          } else {
            caracter_raro = true;
          }
        }
        if (
          mayuscula == true &&
          minuscula == true &&
          caracter_raro == true &&
          numero == true
        ) {
          return true;
        }
      }
      // console.log("in");
      return false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}

.field-icon {
  float: right;
  margin-top: -71px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}
</style>