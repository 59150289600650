<template>
  <div class="wrapper">
    <menu-ver type="superuser" name="Superuser" />
    <div class="container m-3">
      <div class="table-header">
        <button
          v-if="!crud"
          class="btn button buttonCreate"
          @click="getActions(0)"
          style="font-weight: bold"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
        <input
          v-if="!crud"
          class="input-filter"
          @keyup.enter="searchCompanies"
          v-model.trim="filterByName"
          style="height: 39px; text-transform: uppercase"
          :placeholder="$t(`maintenance.companies.name`)"
        />
        <input
          v-if="!crud"
          class="input-filter"
          @keyup.enter="searchCompanies"
          v-model.trim="filterByCIF"
          style="margin-right: 5px; height: 39px; text-transform: uppercase"
          :placeholder="$t(`maintenance.companies.cif`)"
        />
        <label
          v-if="!crud"
          class="form-check-label"
          for="flexCheckDefault"
          style="margin-top: 5px; margin-right: 5px"
        >
          {{ $t("maintenance.companies.iGroup") }}
        </label>
        <input
          v-if="!crud"
          class="form-check-input"
          type="checkbox"
          value=""
          v-model="filterIsGroup"
          id="flexCheckDefault"
          style="height: 39px; width: 38px; margin-top: 0px; margin-right: 5px"
        />
        <input
          v-if="!crud"
          class="input-filter"
          @keyup.enter="searchCompanies"
          v-model.trim="filterByBelongs"
          style="height: 39px; text-transform: uppercase"
          :placeholder="$t(`maintenance.companies.belongs`)"
        />
        <button
          v-if="!crud"
          class="button btn buttonSearch"
          @click="searchCompanies"
          style="margin-right: 5px; margin-right: 3px; margin-top: -2px"
        >
          <i class="fas fa-search"></i>
        </button>
      </div>

      <!-- Add/Edit -->
      <div class="container" v-if="crud">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel" v-if="add">
            {{ $t("maintenance.companies.addTitle", { name: `${companyId}` }) }}
          </h5>
          <h5 class="modal-title" id="exampleModalLabel" v-else>
            {{
              $t("maintenance.companies.editTitle", { name: `${companyId}` })
            }}
          </h5>
          <button type="button" class="btn-close" @click="closeCrud"></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="row">
              <div class="col">
                <div class="col-sm" v-if="!logo">
                  <img
                    src="@/assets/img/logo-default.png"
                    class="img-logo"
                    alt="logo"
                  />
                </div>
                <div class="col-sm" v-else>
                  <img :src="logo" class="img-logo" alt="logo" />
                </div>
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`maintenance.companies.name`) + "*"
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  id="name"
                  v-model.trim="company.emp_nombre"
                  :placeholder="$t(`maintenance.companies.name`) + '*'"
                  maxlength="255"
                />
                <label class="form-label">{{
                  $t(`maintenance.companies.cif`) + " *"
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  id="cif"
                  v-model.trim="company.emp_cif"
                  :placeholder="$t(`maintenance.companies.cif`) + '*'"
                  maxlength="12"
                />
                <label class="form-label">{{
                  $t(`maintenance.companies.email`) + " *"
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="email"
                  class="form-control"
                  id="email"
                  v-model.trim="company.emp_email"
                  :placeholder="$t(`maintenance.companies.email`) + '*'"
                  maxlength="255"
                />
                <hr />
                <div class="row">
                  <div class="col">
                    <label class="form-label">{{
                      $t(`maintenance.companies.user`) + " *"
                    }}</label>
                    <input
                      style="text-transform: uppercase"
                      type="text"
                      class="form-control"
                      id="user"
                      v-model.trim="company.emp_usuario"
                      :placeholder="$t(`maintenance.companies.user`) + '*'"
                      maxlength="20"
                    />
                  </div>
                  <div class="col">
                    <label class="form-label">{{
                      $t(`maintenance.companies.password`) + " *"
                    }}</label>
                    <input
                      v-if="visPas"
                      type="password"
                      class="form-control"
                      id="password"
                      v-model.trim="company.emp_clave"
                      :placeholder="$t(`maintenance.companies.password`) + '*'"
                      maxlength="20"
                    />
                    <input
                      v-else
                      type="text"
                      class="form-control"
                      id="password"
                      v-model.trim="company.emp_clave"
                      :placeholder="$t(`maintenance.companies.password`) + '*'"
                      maxlength="20"
                    />
                    <i
                      v-if="!visPas"
                      @click="visPas = true"
                      class="fas fa-eye-slash field-icon"
                    ></i>
                    <i
                      v-else
                      @click="visPas = false"
                      class="fas fa-eye field-icon"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <span>{{ $t("maintenance.companies.logo") }}:</span>
            <FilePond
              ref="pond"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="false"
              max-files="1"
            />
            <div class="row">
              <div class="col">
                <label class="form-label">{{
                  $t(`maintenance.companies.direction`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  id="direction"
                  v-model.trim="company.emp_direccion"
                  :placeholder="$t(`maintenance.companies.direction`)"
                  maxlength="255"
                />
                <label class="form-label">{{
                  $t(`maintenance.companies.cp`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  id="cp"
                  v-model.trim="company.emp_cp"
                  :placeholder="$t(`maintenance.companies.cp`)"
                  maxlength="10"
                />
                <label class="form-label">{{
                  $t("maintenance.companies.lang") + " *"
                }}</label>
                <select
                  class="form-select"
                  aria-label="Elija una opción"
                  v-model.trim="company.emp_idioma_cd"
                >
                  <option :value="0" disabled>
                    {{ $t("maintenance.companies.lang") }}*
                  </option>
                  <option
                    :value="lang.idio_num"
                    v-for="lang of languages"
                    :key="lang.idio_num"
                  >
                    {{ lang.idio_nombre }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`maintenance.companies.population`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  id="population"
                  v-model.trim="company.emp_poblacion"
                  :placeholder="$t(`maintenance.companies.population`)"
                  maxlength="255"
                />
                <label class="form-label">{{
                  $t(`maintenance.companies.province`)
                }}</label>
                <input
                  style="text-transform: uppercase"
                  type="text"
                  class="form-control"
                  id="province"
                  v-model.trim="company.emp_provincia"
                  :placeholder="$t(`maintenance.companies.province`)"
                  maxlength="50"
                />
                <label class="form-label">{{
                  $t("maintenance.companies.country") + " *"
                }}</label>
                <select
                  class="form-select"
                  aria-label="Elija una opción"
                  v-model.trim="company.emp_pais_cd"
                >
                  <option :value="0" disabled>
                    {{ $t("maintenance.companies.country") }}*
                  </option>
                  <option
                    :value="country.pais_num"
                    v-for="country of countries"
                    :key="country.pais_num"
                  >
                    {{ country.pais_nombre }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="mb-3 radio-group">
                  <div class="form-check">
                    <label for="FormName" class="form-label label-title-radio"
                      >{{ $t("maintenance.companies.companyType") }}*</label
                    >
                  </div>
                  <!-- <div class="form-check"> -->
                  <input
                    class="form-check-input input-radio"
                    type="radio"
                    name="flexGroup"
                    id="belongs"
                    value="0"
                    v-model="company.emp_es_grupo"
                  />
                  <label class="form-check-label label-radio" for="belongs">
                    {{ $t("maintenance.companies.belongs") }}
                  </label>
                  <!-- </div> -->
                  <!-- <div class="form-check"> -->
                  <input
                    class="form-check-input input-radio"
                    type="radio"
                    name="flexGroup1"
                    id="igroup"
                    value="1"
                    v-model="company.emp_es_grupo"
                  />
                  <label class="form-check-label label-radio" for="igroup">
                    {{ $t("maintenance.companies.iGroup") }}
                  </label>
                  <!-- </div> -->
                  <!-- <div class="form-check"> -->
                  <input
                    class="form-check-input input-radio"
                    type="radio"
                    name="flexGroup2"
                    id="single"
                    value="2"
                    v-model="company.emp_es_grupo"
                  />
                  <label class="form-check-label label-radio" for="single">
                    {{ $t("maintenance.companies.single") }}
                  </label>
                  <!-- </div> -->
                </div>
              </div>
              <div class="col">
                <div class="mb-3 radio-group">
                  <div class="form-check">
                    <label for="FormName" class="form-label label-title-radio"
                      >{{ $t("maintenance.companies.designType") }}*</label
                    >
                  </div>
                  <input
                    class="form-check-input input-radio"
                    type="radio"
                    name="flexColor0"
                    id="group"
                    value="0"
                    v-model="company.emp_color"
                  />
                  <label class="form-check-label label-radio" for="group">
                    {{ $t("maintenance.companies.typeOrange") }}
                  </label>
                  <input
                    class="form-check-input input-radio"
                    type="radio"
                    name="flexColor"
                    id="design"
                    value="1"
                    v-model="company.emp_color"
                  />
                  <label class="form-check-label label-radio" for="design">
                    {{ $t("maintenance.companies.typeBlue") }}
                  </label>
                  <input
                    class="form-check-input input-radio"
                    type="radio"
                    name="flexColor"
                    id="design1"
                    value="2"
                    v-model="company.emp_color"
                  />
                  <label class="form-check-label label-radio" for="design1">
                    {{ $t("maintenance.companies.typeGreen") }}
                  </label>
                  <input
                    class="form-check-input input-radio"
                    type="radio"
                    name="flexColor"
                    id="design2"
                    value="3"
                    v-model="company.emp_color"
                  />
                  <label class="form-check-label label-radio" for="design2">
                    {{ $t("maintenance.companies.typeRed") }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="mb-3"
                  v-if="
                    company.emp_es_grupo !== 2 && company.emp_es_grupo !== '2'
                  "
                >
                  <label
                    class="form-label"
                    v-if="company.emp_es_grupo === '0'"
                    >{{ $t("maintenance.companies.group") + " *" }}</label
                  >
                  <label class="form-label" v-else>{{
                    $t("maintenance.companies.group")
                  }}</label>
                  <select
                    class="form-select"
                    aria-label="Elija una opción"
                    v-model.trim="company.emp_pertenece_cd"
                    style="margin-bottom: 0px"
                  >
                    <option :value="0" disabled activate>
                      {{ $t("maintenance.companies.group") }}
                    </option>
                    <option
                      :value="comp.emp_id"
                      v-for="comp of compGroup"
                      :key="comp.emp_id"
                    >
                      {{ comp.emp_nombre }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="mb-3" v-if="!add">
                  <label class="form-label" style="display: block">{{
                    $t(`maintenance.companies.state`)
                  }}</label>
                  <input
                    v-if="company.emp_bloqueado !== 0"
                    style="text-transform: uppercase; margin-bottom: 0px"
                    type="text"
                    class="form-control input-state"
                    id="state"
                    value="Bloqueado"
                    disabled
                    :placeholder="$t(`maintenance.companies.state`)"
                  />
                  <input
                    v-else
                    style="text-transform: uppercase;"
                    type="text"
                    class="form-control input-state"
                    id="state"
                    value="No Bloqueado"
                    disabled
                    :placeholder="$t(`maintenance.companies.state`)"
                  />
                  <button
                    v-if="company.emp_bloqueado !== 0"
                    @click="unlockCompany"
                    class="btn btn-outline-secondary btn-state"
                    type="button"
                  >
                    {{ $t("maintenance.companies.unlock") }}
                  </button>
                  <button
                    v-else
                    @click="lockCompany"
                    class="btn btn-outline-secondary btn-state"
                    type="button"
                  >
                    {{ $t("maintenance.companies.lock") }}
                  </button>
                </div>
              </div>
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div style="margin-bottom: 10pt">
          <button
            id="btnSend"
            v-if="add"
            @click="addCompany"
            type="button"
            class="btn-light btnCrud"
          >
            <i class="fas fa-paper-plane"></i> {{ $t("actions.send") }}
          </button>
          <button
            id="btnSend"
            v-else
            @click="editCompany"
            type="button"
            class="btn-light btnCrud"
          >
            <i class="fas fa-paper-plane"></i> {{ $t("actions.send") }}
          </button>
          <button
            id="btnCleanC"
            @click="closeCrud"
            type="button"
            class="btn-light btnCrud"
          >
            <i class="fas fa-window-close"></i>
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>

      <table
        style="margin-top: 20pt"
        class="table"
        v-if="!crud && companies && companies.length > 0"
      >
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.companies.name") }}</th>
            <th scope="col">{{ $t("maintenance.companies.cif") }}</th>
            <th scope="col">{{ $t("maintenance.companies.email") }}</th>
            <th scope="col">{{ $t("maintenance.companies.user") }}</th>
            <th scope="col">{{ $t("maintenance.companies.state") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody class="scrolling-component" ref="scrollComponent">
          <tr v-for="item in companies" :key="item.emp_id">
            <td>{{ item.emp_nombre }}</td>
            <td>{{ item.emp_cif }}</td>
            <td>{{ item.emp_email }}</td>
            <td>{{ item.emp_usuario }}</td>
            <td>
              <i
                style="margin-left: 18px"
                v-if="item.emp_bloqueado === 1"
                class="fas fa-lock"
              ></i>
              <i v-else style="margin-left: 18px" class="fas fa-lock-open"></i>
            </td>
            <td>
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else-if="!crud">{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("maintenance.companies.deleteTitle", {
                name: `${companyId}`,
              })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("maintenance.companies.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteCompany"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 3">
                {{ $t("errors.5") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 3 && result !== '0'"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { mapMutations, mapState } from "vuex";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  components: {
    MenuVer,
    FilePond,
  },
  data() {
    return {
      companies: [],
      company: {
        emp_id: "",
        emp_nombre: "",
        emp_clave: "",
        emp_email: "",
        emp_cif: "",
        emp_direccion: "",
        emp_poblacion: "",
        emp_cp: "",
        emp_provincia: "",
        emp_es_grupo: 2,
        emp_pertenece_cd: 0,
        emp_usuario: "",
        emp_pais_cd: 0,
        emp_idioma_cd: 0,
        emp_color: 0,
        emp_logotipo: "",
      },
      companyId: "",
      result: "",
      action: 0,
      id: "",
      formError: false,
      loading: false,
      visPas: true,
      add: false,
      languages: [],
      imgText: this.$t("maintenance.companies.imgText"),
      crud: false,
      filterByCIF: "",
      filterByName: "",
      filterByBelongs: "",
      filterIsGroup: false,
      countries: [],
      compGroup: [],
      isGroup: false,
      logo: null,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      denuncias: "",
      messageError: ''
    };
  },
  created() {
    this.getCompanies();
    this.changeBreadCrumb(1);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor"])
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    handleScroll(e) {
      const w = window.innerHeight;
      let element = this.$refs.scrollComponent;
      if (
        element &&
        element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        // console.log("in");
        // // this.getCompanies()
        // for (let i = 0; i < 10; i++) {
        //   this.companies.push({
        //     emp_nombre: "Comunica",
        //     emp_cif: "54645215B",
        //     emp_email: `a${i}`,
        //     emp_clave: "asdasd",
        //   });
        // }
      }
    },
    async getCompanies() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=empresa&ac=listado`
        );
        if (res.data) {
          this.companies = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addCompany() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=empresa&ac=grabar`,
            this.formatDatas()
          );
          // console.log(res);
          this.result = res.data.RESULT;
        } catch (error) {
          console.error(error);
          this.result = "1";
        }
        $("#responseModal").modal("show");
        this.loading = false;
      }
    },
    async editCompany() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=empresa&ac=actualizar`,
            this.formatDatas()
          );
          // console.log(res);
          this.result = res.data.RESULT;
        } catch (error) {
          console.error(error);
          this.result = "1";
        }
        $("#responseModal").modal("show");
        this.loading = false;
      }
    },
    async deleteCompany() {
      this.result = "";
      try {
        const borrado = {
          emp_id: this.id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=empresa&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
        // console.log("in2", this.result);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async getLanguages() {
      // this.languages = [{idio_num: 1, idio_nombre: "aaaaa"}, {idio_num: 2, idio_nombre: "bbbbb"}]
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=idioma&ac=listado`
        );
        if (res.data) {
          this.languages = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCountries() {
      // this.countries = [{pais_num: 1, pais_nombre: "aaaaa"}, {pais_num: 2, pais_nombre: "bbbbb"}]
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=pais&ac=listado`
        );
        if (res.data) {
          this.countries = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCompaniesGroup() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=empresa&ac=grupo`
        );
        if (res.data) {
          this.compGroup = res.data.data;
          // res.data.data.forEach((element) => {
          //   this.compGroup.push(element);
          // });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async unlockCompany() {
      try {
        const unlock = {
          emp_id: this.id,
        };
        // console.log(unlock);
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=empresa&ac=desbloquear`,
          unlock
        );
        // console.log(res);
        if (res.data.RESULT === "0") {
          this.company.emp_bloqueado = 0;
          this.action = 3;
          this.cleanForms();
          $("#responseModal").modal("show");
        } else {
          this.result = res.data.RESULT;
        }
        this.getCompanies();
      } catch (error) {
        console.error(error);
      }
    },
    async lockCompany() {
      try {
        const unlock = {
          emp_id: this.id,
        };
        // console.log(unlock);
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=empresa&ac=bloquear`,
          unlock
        );
        // console.log(res);
        if (res.data.RESULT === "0") {
          this.company.emp_bloqueado = 0;
          this.action = 3;
          this.cleanForms();
          $("#responseModal").modal("show");
        } else {
          this.result = res.data.RESULT;
        }
        this.getCompanies();
      } catch (error) {
        console.error(error);
      }
    },
    getId(event) {
      this.companyId = event[0];
    },
    validateForm() {
      this.messageError = this.$t("errors.fieldOb");
      if (this.company.emp_nombre === "") {
        this.formError = true;
        return false;
      }
      if (!this.isEmailValid()) {
        // console.log("Email erroneo");
        this.formError = true;
        return false;
      }
      if (this.company.emp_cif === "") {
        this.formError = true;
        return false;
      }
      if (!this.validatePassword()) {        
        this.formError = true;
        this.messageError = this.$t("configuration.messageError2");
        return false;
      }
      if (this.company.emp_idioma_cd === 0) {
        this.formError = true;
        return false;
      }
      if (this.company.emp_es_grupo === "") {
        this.formError = true;
        return false;
      }
      if (this.company.emp_color === "") {
        this.formError = true;
        return false;
      }
      if (this.company.emp_usuario === "") {
        this.formError = true;
        return false;
      }
      if (this.company.emp_pertenece_cd === 0) {
        this.emp_pertenece_cd = "";
      }
      if (this.company.emp_pais_cd === 0) {
        this.emp_pais_cd = "";
      }
      this.messageError = '';
      return true;
    },
    cleanForms() {
      if (this.result === "0") {
        this.company = {
          emp_id: "",
          emp_nombre: "",
          emp_clave: "",
          emp_email: "",
          emp_cif: "",
          emp_direccion: "",
          emp_poblacion: "",
          emp_cp: "",
          emp_provincia: "",
          emp_es_grupo: "2",
          emp_pertenece_cd: "",
          emp_usuario: "",
          emp_pais_cd: "",
          emp_idioma_cd: "",
          emp_color: "",
          emp_logotipo: "",
        };
        this.formError = false;
        this.crud = false;
        this.denuncias = "";
        this.getCompanies();
      }
      this.visPas = true;
      this.loading = false;
      this.messageError = '';
    },
    getActions(act, item) {
      this.action = act;
      this.getLanguages();
      this.getCountries();
      this.getCompaniesGroup();
      if (act === 0) {
        this.add = true;
        this.crud = true;
        this.company.emp_pertenece_cd = 0;
        this.company.emp_pais_cd = 0;
        this.company.emp_idioma_cd = 0;
        this.logo = "";
        return;
      }
      if (act === 1 && item) {
        this.crud = true;
        this.add = false;
        this.company = item;
        this.id = item.emp_id;
        this.verImagen(this.id);
        this.denuncias = `/denuncia/${this.id}/0`;
        return;
      }
      if (item.emp_id) {
        this.id = item.emp_id;
      }
    },
    async searchCompanies() {
      let isGroup = "";
      if (this.filterIsGroup) {
        isGroup = "1";
      }
      const filter = {
        emp_nombre: this.filterByName.toUpperCase(),
        emp_cif: this.filterByCIF.toUpperCase(),
        emp_es_grupo: isGroup,
        emp_pertenece: this.filterByBelongs.toUpperCase(),
      };
      // console.log("filter", filter);
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=empresa&ac=listado`,
          filter
        );
        // console.log(res.data);
        if (res.data) {
          this.companies = [];
          this.companies = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    closeCrud() {
      this.result = "0";
      this.cleanForms();
      this.crud = false;
      // console.log(this.company);
    },
    verImagen(id) {
      // console.log('logo')
      this.logo = `${this.servidor}api/ver_logotipo.asp?emp_id=${id}`;
    },
    formatDatas() {
      let formData = new FormData();

      if (this.action === 1) {
        formData.append("emp_id", this.company.emp_id);
      }
      formData.append("emp_nombre", this.company.emp_nombre.toUpperCase());
      formData.append("emp_clave", this.company.emp_clave);
      formData.append("emp_email", this.company.emp_email.toUpperCase());
      formData.append("emp_cif", this.company.emp_cif.toUpperCase());
      formData.append(
        "emp_direccion",
        this.company.emp_direccion.toUpperCase()
      );
      formData.append(
        "emp_poblacion",
        this.company.emp_poblacion.toUpperCase()
      );
      formData.append("emp_cp", this.company.emp_cp.toUpperCase());
      formData.append(
        "emp_provincia",
        this.company.emp_provincia.toUpperCase()
      );
      formData.append("emp_es_grupo", this.company.emp_es_grupo);
      if (this.company.emp_pertenece_cd) {
        formData.append("emp_pertenece_cd", this.company.emp_pertenece_cd);
      }
      if (this.company.emp_usuario) {
        formData.append("emp_usuario", this.company.emp_usuario.toUpperCase());
      } else {
        formData.append("emp_usuario", this.company.emp_usuario);
      }
      formData.append("emp_pais_cd", this.company.emp_pais_cd);
      formData.append("emp_idioma_cd", this.company.emp_idioma_cd);
      formData.append("emp_color", this.company.emp_color);
      if (this.$refs.pond.getFile(0)) {
        formData.append("emp_logotipo", this.$refs.pond.getFile(0).file);
        // console.log("FILE", this.$refs.pond.getFile(0).file);
      } else {
        formData.append("emp_logotipo", "");
        // console.log("FILEERROR");
      }

      return formData;
    },
    isEmailValid() {
      return this.company.emp_email == ""
        ? ""
        : this.reg.test(this.company.emp_email)
        ? true
        : false;
    },
    validatePassword() {
        if (this.company.emp_clave.length >= 8) {
          let mayuscula = false;
          let minuscula = false;
          let numero = false;
          let caracter_raro = false;

          for (var i = 0; i <this.company.emp_clave.length; i++) {
            if (
              this.company.emp_clave.charCodeAt(i) >= 65 &&
              this.company.emp_clave.charCodeAt(i) <= 90
            ) {
              mayuscula = true;
            } else if (
              this.company.emp_clave.charCodeAt(i) >= 97 &&
              this.company.emp_clave.charCodeAt(i) <= 122
            ) {
              minuscula = true;
            } else if (
              this.company.emp_clave.charCodeAt(i) >= 48 &&
              this.company.emp_clave.charCodeAt(i) <= 57
            ) {
              numero = true;
            } else {
              caracter_raro = true;
            }
          }
          if (
            mayuscula == true &&
            minuscula == true &&
            caracter_raro == true &&
            numero == true
          ) {
            return true;
          }
        }
        return false;
    }    
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.field-icon {
  float: right;
  margin-top: -69px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}

.input-filter {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* float: right; */
  margin-right: 3px;
}

input {
  height: 35pt;
  text-indent: 5px;
}

.buttonSearch {
  float: none;
  background-color: #a4a9a8;
  border-radius: 5px;
}

input[type="radio"] {
  height: 1em;
}

#btnCleanC {
  border-bottom-left-radius: 0pt;
  border-top-left-radius: 0pt;
  width: 21%;
  background-color: #a4a9a8;
}

#btnSend {
  background-color: #e99417;
  border-bottom-right-radius: 0pt;
  border-top-right-radius: 0pt;
  width: 74%;
  margin-left: 25pt;
}

.btnCrud {
  padding: 10px;
  width: 200pt;
  border-radius: 50px;
  color: #fff;
}

.imgFile {
  background-color: grey;
}

select {
  margin-bottom: 40px;
  color: #898f96;
  height: 35pt;
}

.img-logo {
  width: 100%;
  margin-top: 4pt;
  max-width: 520px;
}
</style>